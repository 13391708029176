<template>
  <div>
    <v-menu
      bottom
      left
      offset-y
      origin="top right"
      transition="scale-transition"
    >
      <template v-slot:activator="{ on }">
        <v-btn dark icon v-on="on">
            <v-avatar size="40">
                <img
                        v-if="
                  $auth.user() &&
                  $auth.user() &&
                  $auth.user().avatar
                "
                        :src="$http.defaults.apiURL + $auth.user().avatar"
                />
                <i v-else width="128px"><v-icon>mdi-account</v-icon></i>
            </v-avatar>
        </v-btn>
      </template>

      <v-list>
        <v-list-item @click="$store.state.settingsModal = true">
          <v-list-item-title>Настройки профиля</v-list-item-title>
        </v-list-item>
      </v-list>
      <v-list>
        <v-list-item @click="$store.state.securityModal = true">
          <v-list-item-title>Настройки доступа</v-list-item-title>
        </v-list-item>
      </v-list>
      <v-list>
        <v-list-item @click="logout()">
          <v-list-item-title>Выход</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <div v-if="$auth.check()">
      <v-dialog
        max-width="1024"
        v-model="$store.state.settingsModal"
        persistent
      >
        <settings :userId="$auth.user().user_id"></settings>
      </v-dialog>
      <v-dialog
        max-width="1024"
        persistent
        v-model="$store.state.securityModal"
      >
        <security @close="$store.state.securityModal=false"></security>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import settings from "../../views/profile/settings";
import security from "../../views/profile/security";
export default {
  name: "Layout",
  components: {
    settings,
    security,
  },
  data: () => ({}),
  methods: {
    logout() {
      this.$auth.logout({
        makeRequest: true,
        redirect: "/login",
        success() {
          console.log("success " + this.context);
        },
        error() {
          console.log("error " + this.context);
        },
      });
    },
  },
};
</script>

<style>
</style>
