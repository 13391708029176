<template>

      <v-dialog
        v-model="dialog"
        fullscreen
        hide-overlay

      >
        <template v-slot:activator="{ on, attrs }">
          <v-list-item v-bind="attrs" v-on="on">
                <v-list-item-title>{{buttonText}}</v-list-item-title>
              </v-list-item>
        </template>
        <v-card>
          <v-toolbar
            dark
            color="primary"
          >
            <v-btn
              icon
              dark
              @click="dialog = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
            {{title}}
            <v-spacer></v-spacer>

          </v-toolbar>
          <v-card-text
          class="ma-0 pa-0"
        >
          <embed
            :src="src"
            style="width: 100%; height: calc(100vh - 90px)"
          />
        </v-card-text>
        </v-card>
      </v-dialog>

  </template>
  <script>
  export default {
    props:['title','src','buttonText'],
    data () {
        
      return {
        dialog: false,
        
      }
    },
  }
</script>