<template>
    <div id="proile-settings">
        <v-card>
            <v-card-text class="pa-5 border-bottom">
                <h3 class="title blue-grey--text text--darken-2 font-weight-regular d-flex justify-space-between">Настройки доступа<v-btn small @click="$store.state.securityModal=false" fab><v-icon>mdi-close</v-icon></v-btn></h3>
                
            </v-card-text>
            <v-card-text>

                <v-text-field
                    v-model="old_password"
                    label="Старый пароль"
                    filled
                    background-color="transparent"
                    type="password"
                    ></v-text-field>

                <v-text-field
                    v-model="password"
                    label="Новый пароль"
                    type="password"
                    filled
                    background-color="transparent"
                    ></v-text-field>
                <v-text-field
                    v-model="password2"
                    label="Подтвердите новый пароль"
                     type="password"
                    filled
                    background-color="transparent"
                    ></v-text-field>
                <v-btn color="success" :loading="sending" @click="change_password()" class="text-capitalize mr-2 mb-4">Сохранить</v-btn>
                <v-alert             
                    :value="alert"
                    color="success"
                    type="success"
                    dense
                    
                    dark
                    transition="scale-transition"
                    >
                    Сохранено!
                </v-alert>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
  export default {
    name: 'profile-settings',
    components: { 
    },
    data () {
      return {
        sending:false,
        alert:false,
        email: '',
        password: '',
        password2: '',
        old_password: '',
        errors: {}
      }
    },
    methods: {
      change_password () {
        
        if(this.password !== this.password2)
        {
          alert('Введенные пароли не совпадают')
          return false
        }
        this.sending=true
        this.$http.get('auth/change-password',{
          params: {
            old_password: this.old_password,
            new_password: this.password
          }
        })
        .then(() => {
          alert('Ваш пароль успешно изменён')
          this.$emit('close')
        }).catch(e => {
          alert(e.response.data.error)
        }).finally(()=>{
          this.sending=false
        })
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>